import React, { useContext, useEffect, useState } from "react";
import "./SAP.css";
import execute from "../execute.png";
import { IoCloseSharp } from "react-icons/io5";
import { MdArrowRight, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { FaArrowLeft, FaDownload, FaEye } from "react-icons/fa";
import { RiArrowLeftDoubleLine } from "react-icons/ri";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import { showToast } from "../utils/toast";
import {EditOutlined,UnorderedListOutlined,PlusCircleOutlined,EyeFilled,FilterTwoTone,CheckOutlined,InfoCircleOutlined,ArrowLeftOutlined} from '@ant-design/icons';

const SAP = () => {
  const user = localStorage.getItem("user_id");
  const [open, setOpen] = useState(false);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [appCheckbox, setAppCheckbox] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterCodes, setFilterCodes] = useState([]);
  const [openPref, setPrefOpen] = useState(false);
  const [savedPreferences,setSavedPrefernces] = useState([])

  const handleCheckboxChange1 = (option) => {
    let updatedOptions;
    if (filterCodes.some((item) => item.tcode === option && item.fields)) {
      const children = filterCodes.find((item) => item.tcode === option).fields.split(',');
      updatedOptions = selectedOptions1.includes(option)
        ? selectedOptions1.filter((item) => !children.includes(item)) 
        : [...selectedOptions1.filter((item) => !children.includes(item)), option]; 
    } else {
      if (selectedOptions1.includes(option)) {
        updatedOptions = selectedOptions1.filter((item) => item !== option);
      } else {
        updatedOptions = [...selectedOptions1, option];
      }
    }
    setSelectedOptions1(updatedOptions);
    
 
  };

  const handleSubmit = () => {
    const data = {
      user_id: user,
      allowed_combinations: selectedOptions1,
    };
    axios
      .post("https://vmesas.com:5002/save_selection", data)
      .then((res) => {
        showToast(res.data.message);
        handleCancel();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setSelectedOptions1([]);
  };

  const handleExecute = () => {
    const form = document.getElementById("upload-form");
    const formData = new FormData();
    formData.append("file1", form.file1.files[0]);
    formData.append("file2", form.file2.files[0]);
    formData.append("user_id", user);
    axios
      .post("https://vmesas.com:5008/upload", formData)
      .then((res) => {
        if (res.data.results) {
          setResultData(res.data.results);
          setShowModal(true);
        } else {
          alert("No violations found or an error occurred.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get("https://vmesas.com:4012/module_tcodes")
      .then((res) => {
        setAppCheckbox(res.data);
        setFilterCodes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`https://vmesas.com:5013/user-preferences/${user}`)
      .then((res) => {
        console.log(res.data);
        setSavedPrefernces(res.data.allowed_combinations)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchText(newSearchTerm);

    const filtered = appCheckbox.filter((item) =>
      item.tcode.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilterCodes(filtered);
  };

  const handleDownloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(resultData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Violations");
    XLSX.writeFile(wb, "violations.xlsx");
  };

  return (
    <div className="sapmain">
      <div className="navbar1">
        <FaArrowLeft />{" "}
        <Link to="/Sodmain" className="home_link">
          SAP SOD
        </Link>
      </div>
      <div className="sapcont">
        <i onClick={() => setPrefOpen(!openPref)} style={{fontSize:'28px',cursor:'pointer'}}>
          <MdOutlineKeyboardDoubleArrowRight />
        </i>
        {
          openPref && (
            <div className="pref_card">
            <p style={{textAlign:'center'}}>Already Saved Preference</p>
           <div>

              {
                savedPreferences.map((item)=>{
                  return  <h5 className="pref_text"> <MdArrowRight/> {item}</h5>
                })
              }
            </div>
      
          </div>
  
          )
        }

      
        <div className="sapcard">
          <div className="ii1">
            <label>API KEY</label>
            <input
              type="text"
              className="APIIN"
              placeholder="Enter your API key"
            />
          </div>
          <div className="ii1">
            <span>OR</span>
          </div>
          <form id="upload-form">
            <div className="ii1">
              <p>Step 1:</p>
              <Tooltip title="Users and Roles">
              <InfoCircleOutlined/></Tooltip>
              <input
                type="file"
                id="file1"
                name="file1"
                accept=".csv"
                className="filein"
                required
              />
            </div>
            <div className="ii1">
              <p>Step 2:</p><Tooltip title="Roles and Auth Objects">
              <InfoCircleOutlined/></Tooltip>
              <input
                type="file"
                id="file2"
                name="file2"
                accept=".csv"
                className="filein"
                required
              />
            </div>
          </form>
          <div className="ii1">
            <button className="button-19" onClick={handleExecute}>
              <img src={execute} className="dimg" /> Execute
            </button>
          </div>
        </div>

        <div>
          <div>
            <div className="drawer-cont">
              <i onClick={() => setOpen(!open)}>
                <RiArrowLeftDoubleLine />
              </i>
            </div>
            {open && (
              <div className="drawer">
                <div class="search-box">
                  <input
                    type="text"
                    class="search-input"
                    placeholder="Search SAP Objects"
                    onChange={handleSearch}
                  />
                  <button type="submit" class="search-btn">
                    <svg class="search-icon" viewBox="0 0 24 24">
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                    </svg>
                  </button>
                </div>

                <div className="dropdown1">
                  <div className="drop1">
                    <div className="checkbox-dropdown">
                      {filterCodes.map((option, index) => (
                        <div key={index}>
                          <label>
                            <input
                              type="checkbox"
                              value={option.tcode}
                              checked={selectedOptions1.includes(option.tcode)}
                              onChange={() => handleCheckboxChange1(option.tcode)}
                            />
                            {option.tcode  }- {option.description}
                          </label>
                          {option.fields && (
                            <div style={{ display: "flex", flexDirection: "column", marginTop: "4px" }}>
                              {option.fields.split(",").map((item, idx) => (
                                <label key={idx} style={{ marginLeft: "10px", fontSize: "14px" }}>
                                  <input
                                    type="checkbox"
                                    value={item}
                                    checked={selectedOptions1.includes(item) && selectedOptions1.includes(option.tcode)}
                                    onChange={() => handleCheckboxChange1(item)}
                                    disabled={!selectedOptions1.includes(option.tcode)}
                                  />
                                  <i>
                                    <FaEye />
                                  </i>{" "}
                                  {item}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="actionbtns">
                  <button onClick={handleSubmit} className="submit_btn">
                    submit
                  </button>
                  <button onClick={handleCancel} className="cancel_btn">
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="result_modal">
          <div className="modal-box">
            <div>
              <div className="close_btn" onClick={() => setShowModal(false)}>
                <IoCloseSharp />
              </div>
              <button className="download_btn" style={{ marginLeft: "670px" }} onClick={handleDownloadExcel}>
                <FaDownload />{" "}
              </button>
            </div>
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Role Name</th>
                    <th>Violation</th>
                  </tr>
                </thead>
                <tbody>
                  {resultData?.map((result, index) => (
                    <tr key={index} className={result.violation === "Violated" ? "violated" : ""}>
                      <td>{result.user_name}</td>
                      <td>{result.role_name}</td>
                      <td>{result.violation}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SAP;