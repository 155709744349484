import React, {  useEffect, useState } from 'react'
import { Flex,Button, Select } from 'antd';
import './Licensetab.css'
import axios from 'axios';
import gear from '../gear.png';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import SimpleBarChart from './SimpleBarChart';
import SimplePieChart from '../SimplePieChart';
import Chart from 'react-apexcharts'
import ResultTable from './Table';
import {EditOutlined,UnorderedListOutlined,PlusCircleOutlined,EyeFilled,FilterTwoTone,CheckOutlined,InfoCircleOutlined,ArrowLeftOutlined,SyncOutlined} from '@ant-design/icons';
import { MdArrowRight, MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';

const Licensetab = () => {

    const navigate = useNavigate();
    const [selectedSoftware, setSelectedSoftware] = useState('');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('');
    const [showStats,setShowStats] =useState(null)
    const [show,setShow] =  useState(false)
    const [appNames,setAppNames] = useState([])
    const [showError,setShowError] = useState(false)
    const [customerSoftwares,setCustomerSoftewares] = useState([])
    const [openSoftwares,setOpenSoftwares] = useState(false)

    const Donut_Options = {
      series: [44, 55, 41, 17, 15],
      labels: ['A', 'B', 'C', 'D', 'E']
    }
    const t_options = [
      {
        label:"Last 30 Days",
        value:30
      },
      {
        label:"Last 60 Days",
        value:60
      }
    ]
    const handleSoftwareChange = (value) => {
      setSelectedSoftware(value);
    };
  
    const handleTimePeriodChange = (value) => {
      setSelectedTimePeriod(value);
    }

    const handleSubmit = (id,time)=>{
     console.log(id,time)
     const user = localStorage.getItem('user_id')
     
     axios.get(`https://vmesas.com:5005/getAppStatsByCustId?custid=${user}&days=${time}&softwareId=${id}`).then((res)=>{
        console.log(res)
        if(res.data[0]){
          setShowStats(res.data[0])
          setShow(true)
          setShowError(false)
        }else{
          setShow(false)
          setShowError(true)
        }
     })
    }
    useEffect(()=>{
      axios.get('https://vmesas.com:5004/get_software').then((res)=>{
        console.log(res)
        const names = res.data.map((item) => ({
          label: item.softwarename,
          value: item.softwareid
        }));
        setAppNames(names)
        
      }).catch((err)=>{
        console.log(err)
      })
    },[])

    useEffect(()=>{
      axios.get('https://vmesas.com:5016/customersoftware').then((res)=>{
        console.log(res)
        setCustomerSoftewares(res.data)
      }).catch((err)=>{
        console.log(err)
      })
    },[])
    const handleSidebar  = () =>{
      setOpenSoftwares(!openSoftwares)
    }

  console.log(showStats)
  return (
    < div className='license_container'>
      <Flex justify='space-between' align='center'>
      <Button style={{marginTop:"10px"}} onClick={()=>{navigate('/authpage')}}><ArrowLeftOutlined />Homepage</Button>
      <h2>License Management</h2>
      <Button style={{marginTop:"10px"}} onClick={() => navigate('/configure')}> Configure </Button>
      </Flex>
        <div className="filter-container">
          <i
            style={{ fontSize: "28px", cursor: "pointer", color: "black" }}
            onClick={handleSidebar}
          >
            <MdOutlineKeyboardDoubleArrowRight />
          </i>
          {openSoftwares && (
            <div className="customer_software">
              <p style={{ textAlign: "center" }}>Software License</p>
              <div>
                {customerSoftwares.map((item) => {
                  return (
                    <div style={{ display: "flex" }}>
                      <p>{item.softwarename} </p> <p>- {item.nooflicenses}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <Flex align='center' justify='center' gap={"30px"}>
          <Select placeholder="Select Softwares" style={{width:"200px"}} options={appNames}  onChange={handleSoftwareChange}/>
          <Select placeholder="Select TimePeriod" style={{width:"200px"}} options={t_options} onChange={handleTimePeriodChange}/>
          <Button onClick={()=>{handleSubmit(selectedSoftware,selectedTimePeriod)}}>
            Submit
          </Button>
        </Flex>

      {
      showError && <p className='error_msg'>No stats found</p>
    }
    {
      show &&
    <div className='stats_container'>
    <SimpleBarChart statsData = {showStats} />
    <SimplePieChart statsData = {showStats} />
    <ResultTable statsData = {showStats}  />
    </div>
    } 
    </div>
  )
}

export default Licensetab