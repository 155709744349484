// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result_table{
    margin-top: 70px;
    width: 450px;
}`, "",{"version":3,"sources":["webpack://./src/components/Table.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".result_table{\r\n    margin-top: 70px;\r\n    width: 450px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
