import * as Yup from "yup";

export const SignUpSchema = Yup.object().shape({
    customerName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("customer name is required"),

    contactPerson: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("contact person is required"),  

    email: Yup.string().email().notOneOf(['gmail.com','yahoo.com','hotmail.com','rediff.com'],'This  email domain is not allowed').matches(/@(?!@gmail\.com$|hotmail\.com$|yahoo\.com$\rediff\.com$)/,'Invalid email domain').required("Email is required"),
    phoneNumber: Yup.string().matches(/^[0-9]{10}$/,'Invalid Phone Number').required('Phone Number is required')
      
  });

export const loginSchema = Yup.object().shape({

  email: Yup.string().email().notOneOf(['gmail.com','yahoo.com','hotmail.com','rediff.com'],'This  email domain is not allowed').matches(/@(?!@gmail\.com$|hotmail\.com$|yahoo\.com$\rediff\.com$)/,'Invalid email domain').required("Email is required"),
  
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum")

})  