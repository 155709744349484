import React, { useEffect, useState } from 'react';
import './Mvconnect.css';
import plus from '../plus.png';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import { showToast } from '../utils/toast';


const Mvconnect = () => {
  const user = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const [flows, setFlows] = useState([]);
  const [showValueMappings, setShowValueMappings] = useState(false);
  const [valueMappings, setValueMappings] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [showMonitorModal, setShowMonitorModal] = useState(false);
  const [interval, setInterval] = useState('immediately');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [logs, setLogs] = useState([]);

  const loadFlows = () => {
    fetch(`https://vmesas.com:5010/flows?userId=${user}`)
      .then(response => response.json())
      .then(data => setFlows(data))
      .catch(error => console.error('Error loading flows:', error));
  };

  const handleRadioChange = (event) => {
    const selectedId = parseInt(event.target.value); 
    setSelectedRowId(selectedId);
  };

  const handleSchedule = ()=>{

    console.log(selectedRowId)
    setShowModal(true);
  }

  const fetchLogs = (e) => {
    e.preventDefault()
    const queryParams = new URLSearchParams({
        selectedRowId,
        startDate,
        endDate
    }).toString();

    fetch(`https://vmesas.com:1900/api/logs?${queryParams}`)
        .then(response => response.json())
        .then(data => {
          console.log(data)
            setLogs(data);
        })
        .catch(error => console.error('Error fetching logs:', error));
};

  const handleMonitor = () =>{
   setShowMonitorModal(true)
  }

  const onClose = ()=>{
      setShowMonitorModal(false)
  }


  const toggleValueMappings = (mappings) => {
    console.log(mappings)
    setValueMappings(mappings);
    setShowValueMappings(true);
  };

  const expandCollapseAll = (expand) => {
    const newFlows = flows.map(flow => {
      return {
        ...flow,
        showMappings: expand
      };
    });
    setFlows(newFlows);
  };


  const handleSubmit = () =>{

    axios.post('https://vmesas.com:1900/add_flow',{
      flow_id: selectedRowId,
      interval: interval,
    }).then((res)=>{
      console.log(res.data.message)
      showToast(res.data.message)
    })
     
    setShowModal(false)
  }

  console.log(flows)
  

 
  return (
    <div className='modmain'>
      <div style={{display:'flex',alignItems:'center'}}>
        <div className="navbar1">
          <FaArrowLeft /> <Link to="/authpage" className="home_link">Homepage</Link>
        </div>
        <div className='modnavbar'>VConnect</div>
        <div className="side-btns" style={{marginRight:'40px'}}>
      <button className="side-btn schedule_btn"onClick={handleSchedule} >S</button>
      <button className="side-btn monitor_btn" onClick={handleMonitor} >M</button>
      </div>
      </div>
      <div className='modmainview'>
        <div className='addflow'><button className='addflowbtn' onClick={() => navigate('/mvconnect')}><img src={plus} className='llogo' />Add new FLow</button></div>
        <div >
         

 <div>
        <div className="inte-container mt-5">
       
      <h2 className="heading">Integration Flows</h2>
      <div className="action_btn">
        <div className="btns"> 

      <button className="btn" id="load_btn" onClick={loadFlows}>Load Flows</button>
      <button className="btn" id="expandall_btn" onClick={() => expandCollapseAll(true)}>Expand All</button>
      <button className="btn" id="collapse_btn" onClick={() => expandCollapseAll(false)}>Collapse All</button>
        </div>
   
        </div>
      <table className="table_inte">
        <thead>
          <tr>
            <th>Select</th>
            <th>ID</th>
            <th>Source System</th>
            <th>Destination System</th>
            <th>Actions</th>
          </tr>
        </thead>
        {showModal && (
                <div className="modal">
                    <div className="smodal-content">
                        <h2>Schedule Integration</h2>
                        <p className="close-schedule" onClick={() => setShowModal(false)}>X</p>
                        <div>
                            <label htmlFor="interval">Schedule:</label>
                            <select id="interval" value={interval} onChange={(e) => setInterval(e.target.value)}>
                                <option value="immediately">Immediately</option>
                                <option value="5mins">In 5 Minutes</option>
                                <option value="15mins">In 15 Minutes</option>
                                <option value="hourly">Hourly</option>
                            </select>
                        </div>
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            )}

{showMonitorModal && (
                <div className="modal">
                    <div className="modal-content">
                    <div>
                    <button className="close-button" onClick={onClose}>X</button>
            <h1 style={{color:"black"}}>Log Monitoring Dashboard</h1>
            <form onSubmit={fetchLogs}>
               <div style={{display:"flex",padding:"10px" , justifyContent:"space-around"}}>

                <div>
                    <label htmlFor="startDate" style={{color:"black"}}>Start Date:</label>
                    <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="endDate" style={{color:"black"}}>End Date:</label>
                    <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                </div>
               </div>
                <button onClick={fetch} >Filter Logs</button>
            </form>

            <table id="logsTable">
                <thead>
                    <tr>
                        <th>Flow ID</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Status</th>
                        <th>Error</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log, index) => (
                        <tr key={index}>
                            <td>{log.flow_id}</td>
                            <td>{log.start_time}</td>
                            <td>{log.end_time || 'N/A'}</td>
                            <td>{log.status}</td>
                            <td>{log.error_message || 'None'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
                    </div>
                </div>
            )}

        <tbody>
          {flows.map(flow => (
            <React.Fragment key={flow.id}>
              <tr>
              <td>
                      <input
                        type="radio"
                        name="selectedRow"
                        value={flow.id}
                        checked={parseFloat(selectedRowId) === flow.id}
                        onChange={handleRadioChange}
                      />
                    </td>
                <td>{flow.id}</td>
                <td>{flow.source_system}</td>
                <td>{flow.destination_system}</td>
                <td>
                  <button className="btn  expand_btn" onClick={() => {
                    const newFlows = flows.map(f => {
                      if (f.id === flow.id) {
                        return { ...f, showMappings: !f.showMappings };
                      }
                      return f;
                    });
                    setFlows(newFlows);
                  }}>
                    {flow.showMappings ? 'Collapse' : 'Expand'}
                  </button>
                </td>
              </tr>
              {flow.showMappings &&
                <tr>
                  <td colSpan="4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Source Field</th>
                          <th>Destination Field</th>
                          <th>Value Mapping</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                         flow.mappings.length ===0 && <p>No mapping found</p>
                        }
                        {flow.mappings.map((mapping, index) => (
                          <tr key={index}>
                            <td>{mapping.sourceField}</td>
                            <td>{mapping.destinationField}</td>
                            <td>
                              {mapping.valueMappings != "" ? (
                                <button className="btn show_btn" onClick={() => toggleValueMappings(mapping.valueMappings)}>
                                  Show
                                </button>
                              ) : (
                                'N/A'
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              }
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {
        showValueMappings && 
        <div  className="modal"  tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close-btn" onClick={() => setShowValueMappings(false)} aria-label="Close">
                <span aria-hidden="true" className="cross">&times;</span>
              </button>
              <h5 className="modal-title">Value Mappings</h5>
            </div>
            <div className="modal-body">
              <table className="table_result">
                <thead>
                  <tr>
                    <th>Source Value</th>
                    <th>Destination Value</th>
                  </tr>
                </thead>
                <tbody>
                  {valueMappings.map((item, index) => (
                    <tr key={index}>
                      <td>{item.sourceValue}</td>
                      <td>{item.destinationValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn  " id="close_btn" onClick={() => setShowValueMappings(false)}>Close</button>
            </div>
          </div>
        </div>
      </div>

      }
    
      </div>

        </div>



        </div>
       
      </div>
    </div>
  ) 
}

export default Mvconnect





