// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sodmain_container{
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
`, "",{"version":3,"sources":["webpack://./src/components/Sodmain.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;AAEvB","sourcesContent":[".sodmain_container{\r\n    width: 100%;\r\n    height: 100vh;\r\n    background-color: black;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n   \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
