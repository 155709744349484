// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.almain
{
    width: 60%;
    /* background-color: #F2F1EB; */
    height:91vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:40px
}
.allmain
{
    width:100%;
   background-color: #3C5B6F;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Alogin.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,+BAA+B;IAC/B,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB;AACJ;AACA;;IAEI,UAAU;GACX,yBAAyB;IACxB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".almain\r\n{\r\n    width: 60%;\r\n    /* background-color: #F2F1EB; */\r\n    height:91vh;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap:40px\r\n}\r\n.allmain\r\n{\r\n    width:100%;\r\n   background-color: #3C5B6F;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
