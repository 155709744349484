import React, { useContext } from 'react'
import './Dnavbar.css'
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../utils/Context/userContext';
import { RxAvatar } from "react-icons/rx";
const Dnavbar = () => {

  const navigate = useNavigate()
  const {setUser,setIsAuthenticated,useremail} = useContext(UserContext)

  const handleLogout =()=>{
     setUser(null)
     localStorage.removeItem('user_id')
     setIsAuthenticated(false)
     navigate('/login')
  }
  return (
    <div className='Dnav'>
         <h1 className='Logo'>VMESAS</h1>
         <div className='user_avatar'>
          <RxAvatar />
        <div className='profile_box'>
          <p>{useremail}</p>
          <button className='logout_btn' onClick={handleLogout}>
            Logout <BiLogOut />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Dnavbar