import  { useState, useEffect } from 'react';
import axios from 'axios';
import './Vconnect.css'
import {showToast} from '../utils/toast/index'
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const Vconnect = () => {
  const user = localStorage.getItem("user_id");
  const [softwares, setSoftwares] = useState([]);
  const [sourceSoftware, setSourceSoftware] = useState('');
  const [destinationSoftware, setDestinationSoftware] = useState('');
  const [sourceFields, setSourceFields] = useState([]);
  const [destinationFields, setDestinationFields] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [valueMappings, setValueMappings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate()
  useEffect(() => {
    fetchSoftware();
  }, []);



  const closeModal = ()=>{
    setIsModalOpen(false)
  }

  const fetchSoftware = async () => {
    try {
      const response = await axios.get('https://vmesas.com:5011/api/software');
      setSoftwares(response.data);
    } catch (error) {
      console.error('Failed to fetch softwares', error);
    }
  };

  const fetchFields = async (softwareId, isSource) => {
    try {
      const response = await axios.get(`https://vmesas.com:5011/api/fields/${softwareId}`);
      if (isSource) {
        setSourceFields(response.data.fields || []);
      } else {
        setDestinationFields(response.data.fields || []);
      }
    } catch (error) {
      console.error('Failed to fetch fields', error);
    }
  };

  const handleSoftwareChange = (softwareId, isSource) => {
    if (isSource) {
      setSourceSoftware(softwareId);
    } else {
      setDestinationSoftware(softwareId);
    }
    fetchFields(softwareId, isSource);
  };



  const addFieldMapping = () => {
    setMappings([...mappings, { sourceField: '', destinationField: '', valueMappings: [] }]);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedMappings = mappings.map((mapping, i) => {
      if (i === index) {
        return { ...mapping, [field]: value };
      }
      return mapping;
    });
    setMappings(updatedMappings);
  };

  const addValueMapping = (mappingIndex) => {
    const updatedMappings = mappings.map((mapping, i) => {
      if (i === mappingIndex) {
        const newValueMappings = [...mapping.valueMappings, { sourceValue: '', destinationValue: '' }];
        return { ...mapping, valueMappings: newValueMappings };
      }
      return mapping;
    });
    setMappings(updatedMappings);
  };

  const handleValueMappingChange = (mappingIndex, valueIndex, field, value) => {
    const updatedMappings = mappings.map((mapping, i) => {
      if (i === mappingIndex) {
        const newValueMappings = mapping.valueMappings.map((valueMapping, j) => {
          if (j === valueIndex) {
            return { ...valueMapping, [field]: value };
          }
          return valueMapping;
        });
        return { ...mapping, valueMappings: newValueMappings };
      }
      return mapping;
    });
    setMappings(updatedMappings);
  };

  const removeValueMapping = (mappingIndex, valueIndex) => {
    const updatedMappings = mappings.map((mapping, i) => {
      if (i === mappingIndex) {
        const newValueMappings = mapping.valueMappings.filter((_, j) => j !== valueIndex);
        return { ...mapping, valueMappings: newValueMappings };
      }
      return mapping;
    });
    setMappings(updatedMappings);
  };

  const submitMappings = () => {
    // Assuming `softwares` array contains objects with `id` and `name` properties
    const sourceSoftwareName = softwares.find(software => software.id === Number(sourceSoftware))?.name;
    const destinationSoftwareName = softwares.find(software => software.id === Number(destinationSoftware))?.name;

    // Convert your mappings to the format expected by the API
    const formattedMappings = mappings.map(mapping => ({
      sourceField: mapping.sourceField,
      destinationField: mapping.destinationField,
      valueMappings: mapping.valueMappings
    }));

    // Construct the payload according to the structure expected by your API
    const payload = {
      userId:user,
      source: sourceSoftwareName, // Assuming the API expects software names
      destination: destinationSoftwareName,
      destination_software_id: destinationSoftware.toString(), // Use IDs as strings if that's what the API expects
      source_software_id: sourceSoftware.toString(),
      mappings: formattedMappings,
    };

    // Make the API call with the correctly structured payload
    axios.post('https://vmesas.com:5009/flow', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      console.log(response.data); // Handle success
      showToast("Flow created successfully");
      navigate('/Vconnect'); 
    })
    .catch(error => {
      console.error('Error:', error.response?.data || error); // Handle error
    });
};

const removeFieldMapping = (indexToRemove) => {
  const updatedMappings = mappings.filter((_, index) => index !== indexToRemove);
  setMappings(updatedMappings);
};

  return (
    <div className='vconnect_container'>
       <div className="navbar1">
        <FaArrowLeft />{" "}
        <Link to="/Vconnect" className="home_link">
         Vconnect
        </Link>
      </div>
      <div className="select-container">
        <div className="dropdown-container">
        <label htmlFor="sourceSoftware" className='label'>Source Software:</label>
          <select className="form-control select-dropdown" value={sourceSoftware} onChange={(e) => handleSoftwareChange(e.target.value, true)}>
            <option value="">Select Source</option>
            {softwares.map((software) => (
              <option key={software.id} value={software.id}>
               
                {software.name}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown-container">
        <label htmlFor="destinationSoftware" className='label'>Destination Software:</label>
          <select className="form-control select-dropdown" value={destinationSoftware} onChange={(e) => handleSoftwareChange(e.target.value, false)}>
            <option value="">Select Destination</option>
            {softwares.map((software) => (
              <option key={software.id} value={software.id}>
                {software.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    <div className="container_v">
      <table className="table">
        <thead>
          <tr>
            <th>Source Field</th>
            <th>Destination Field</th>
            <th>Value Mapping</th>
          </tr>
        </thead>
        <tbody>
          {mappings.map((mapping, index) => (
            <tr key={index}>
              <td>
                <select className="form-control select-dropdown" value={mapping.sourceField} onChange={(e) => handleFieldChange(index, 'sourceField', e.target.value)}>
                <option value=""></option>
                  {sourceFields.map((field) => (
                    <option key={field} value={field}>
                      {field}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select className="form-control select-dropdown" value={mapping.destinationField} onChange={(e) => handleFieldChange(index, 'destinationField', e.target.value)}>
                <option value=""></option>
                  {destinationFields.map((field) => (
                    <option key={field} value={field}>
                      {field}
                    </option>
                  ))}
                </select>
              </td>
              <td>

                 <div>

                 {
                  isModalOpen && <>
                  <div className='modal_v'>
                  <button className="close-btn" onClick={closeModal}>Close</button>

                <button className="btn_v add-value-btn" onClick={() => addValueMapping(index)}>
                add mapping
                </button>
                {mapping.valueMappings &&   mapping.valueMappings.map((valueMapping, valueIndex) => (
                  <div key={valueIndex} className="value-mapping-container">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Source Value"
                      value={valueMapping.sourceValue}
                      onChange={(e) => handleValueMappingChange(index, valueIndex, 'sourceValue', e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Destination Value"
                      value={valueMapping.destinationValue}
                      onChange={(e) => handleValueMappingChange(index, valueIndex, 'destinationValue', e.target.value)}
                    />
                    <button className="btn_v remove-btn" onClick={() => removeValueMapping(index, valueIndex)}>
                      Remove
                    </button>
                  </div>
                ))}
            
                 <button className="btn_v " onClick={() => setIsModalOpen(false)}>
                  Save
                </button>
            
                 
                      
                  </div>
                  </>
                 }
                 </div>
   <div style={{display:'flex'}}>

                <button className="btn_v add-value-btn" onClick={() => setIsModalOpen(true)}>
                  Value Mapping
                </button>
                <button className="btn_v remove-btn" onClick={() => removeFieldMapping(index)}>
                Remove
              </button>
   </div>
               
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='btns'>

      <button className="btn_v add-field-btn" onClick={addFieldMapping}>
        Add Field Mapping
      </button>
      <button className="btn_v submit-btn" onClick={submitMappings}>
        Submit Mappings
      </button>
      
      </div>
    </div>
    </div>
  );
};

export default Vconnect;