import React, { useRef } from 'react'
import './Homepage.css'
import { motion } from 'framer-motion'
import Solutions from './Solutions'
import Services from './Services'
import CaseStudies from './CaseStudies'
import { solutionRef } from '../utils/helper'

const Homepage = () => {
  
  const serviceRef = useRef(null)
  const caseStudiesRef = useRef(null) 

  return (
    <div>
    <div className='Main'>
      <div className='landingpage'>
    <motion.div
    initial={{opacity:0,y:-10}}
    animate={{opacity:1,y:10}}
    transition={{duration:0.5}}
    >

      <div className='mainname'>
        Welcome to VMESAS
      </div>
      <div className='motto'>
      Automate,Compliance,Integrate,Spend analysis
      </div>
</motion.div>
      </div>
    </div>
    <div>
    <Solutions/>
    </div>
    <div>
    <Services/>
    </div>
    <div>
    <CaseStudies/>
    </div>
    </div>
  )
}

export default Homepage