import { TbArrowsJoin } from 'react-icons/tb'
import { Card } from 'antd'
import './Services.css'
const Services = () =>{
    return (
        <div className="main_div" id="service_div">
            <h1 className="heading">Services</h1>
            <div className='card_wrapper'>
<Card
    title={<span className='card-heading'>License Audit</span>}
    style={{maxWidth:"250px"}}
>
    <p className='Data'>We provide License management to our customers to provide more insights on their business needs.</p>
</Card>

<Card
    title={<span className='card-heading'>ITGC Audit</span>}
    style={{maxWidth:"250px"}}
>
    <p className='Data'>Our Specialized team help to perform ITGC audits for our customers.</p>
</Card>

<Card
    title={<span className='card-heading'>Business<br/> Optimization</span>}
    style={{maxWidth:"250px"}}
>
    <p className='Data'>With our expertise in business process consulting, we help our customers to stream line the business process</p>
</Card>
</div>
        </div>
    )
}

export default Services