import { createContext ,useState} from "react";
export const UserContext  =  createContext()

function UserProvider({ children }) {
    const [user,setUser] = useState(null)
    const [useremail,setUserEmail] = useState("")
    const [isAuthenticated,setIsAuthenticated] = useState(null)
    return (
      <UserContext.Provider value={{user,setUser,isAuthenticated,setIsAuthenticated,useremail,setUserEmail }}>
          {children}
      </UserContext.Provider>
    );
}

export { UserProvider };