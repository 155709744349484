import React, { Fragment, useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { UserContext } from './Context/userContext';


const ProtectedRoute = ({ component: Component,...routeProps }) => {
  const { isAuthenticated } = useContext(UserContext)

 const id = localStorage.getItem('user_id')
  if(!isAuthenticated){
    return <Navigate to='/login' />
  }


  return (
    <Fragment>
    { isAuthenticated ? (
       <Component {...routeProps} />
      ) : null}
    </Fragment>
  );
};

export default ProtectedRoute;