import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const MyChart = ({statsData}) => {
  const data = [
    { month: 'Jan', activeUsers: statsData.users_accessed_30d, inactiveUsers: statsData.inactive_users_30d,deactivatedUsers:statsData.deactivated_users_30d },
    // { month: 'Feb', activeUsers: 3000, inactiveUsers: 1398 },
    // { month: 'Mar', activeUsers: 2000, inactiveUsers: 9800 },
    // Add more data as needed
  ];

  return (
  <LineChart width={380} height={300} data={data}>
    <CartesianGrid stroke="#ccc" />
    <XAxis dataKey="month" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="activeUsers" stroke="#9EDDFF" name="Active Users" />
    <Line type="monotone" dataKey="inactiveUsers" stroke="#9BB8CD" name="Inactive Users" />
    <Line type="monotone" dataKey="deactivatedUsers" stroke="#9BB8CD" name="Deactivated Users" />
  </LineChart>
  )
  };

export default MyChart;
