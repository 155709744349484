// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_div{
    height: 90vh;
    width: 100%;
    background-color: #3C5B6F;

}
.heading{
  color: white;
  text-align: center;
  display: grid;
}
.card_wrapper{
    display: flex;
    padding: 40px 80px;
    justify-content: space-between;
    margin-top: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/CaseStudies.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;;AAE7B;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,gBAAgB;AACpB","sourcesContent":[".main_div{\r\n    height: 90vh;\r\n    width: 100%;\r\n    background-color: #3C5B6F;\r\n\r\n}\r\n.heading{\r\n  color: white;\r\n  text-align: center;\r\n  display: grid;\r\n}\r\n.card_wrapper{\r\n    display: flex;\r\n    padding: 40px 80px;\r\n    justify-content: space-between;\r\n    margin-top: 60px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
