import { useRef } from "react"

export const scrollSection = (item)=>{
    if(item.current){
        item.current.scrollIntoView({behavior:'smooth'})
    }
}

// export const solutionRef = useRef(null)




