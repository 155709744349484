import React, { useContext, useEffect, useState } from "react";
import "./ExpandCollapse.css";
import { showToast } from "../utils/toast";

const ExpandCollapse = () => {
  const user = localStorage.getItem('user_id');
  const [softwareOptions, setSoftwareOptions] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const [selectAllOnboarding, setSelectAllOnboarding] = useState(false);
  const [selectAllOffboarding, setSelectAllOffboarding] = useState(false);

  useEffect(() => {
    fetchSoftwareOptions();
  }, []);

  const fetchSoftwareOptions = async () => {
    try {
      const response = await fetch('https://vmesas.com:5003/get_software_options');
      const softwareOptionsData = await response.json();
      setSoftwareOptions(softwareOptionsData);
    } catch (error) {
      console.error('Error fetching software options:', error);
    }
  };

  const handlePreferenceChange = (softwareId, preference) => {
    setPreferences(prevState => {
      const existingPreferenceIndex = prevState.findIndex(pref => pref.software_id === softwareId);
      if (existingPreferenceIndex !== -1) {
        const updatedPreferences = [...prevState];
        updatedPreferences[existingPreferenceIndex] = {
          ...updatedPreferences[existingPreferenceIndex],
          [preference]: !updatedPreferences[existingPreferenceIndex][preference]
        };
        return updatedPreferences;
      } else {
        return [
          ...prevState,
          {
            "software_id": softwareId,
            "offboarding": preference === "offboarding",
            "onboarding": preference === "onboarding"
          }
        ];
      }
    });
  };

  const handleSelectAll = (preference) => {
    if (preference === "onboarding") {
      setSelectAllOnboarding(!selectAllOnboarding);
      setPreferences(prevState => {
        return softwareOptions.map(option => ({
          "software_id": option.softwareid,
          "onboarding": !selectAllOnboarding,
          "offboarding": prevState.find(pref => pref.software_id === option.softwareid)?.offboarding || false
        }));
      });
    } else if (preference === "offboarding") {
      setSelectAllOffboarding(!selectAllOffboarding);
      setPreferences(prevState => {
        return softwareOptions.map(option => ({
          "software_id": option.softwareid,
          "onboarding": prevState.find(pref => pref.software_id === option.softwareid)?.onboarding || false,
          "offboarding": !selectAllOffboarding
        }));
      });
    }
  };

  const submitPreferences = async () => {
    console.log(preferences);
    try {
      const response = await fetch('https://vmesas.com:5003/submit_preferences', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user, preferences })
      });
      if (!response.ok) throw new Error('Failed to submit preferences');
      const result = await response.json();
      showToast(result.message || 'Preferences submitted successfully');
      setPreferences([]);
    } catch (error) {
      console.error('Error submitting preferences:', error);
      alert('Failed to submit preferences');
    }
  };

  const cancelSelection = () => {
    setPreferences([]);
    setSelectAllOffboarding(false)
    setSelectAllOnboarding(false)
  };

  return (
    <div className="ecmain">
      <div className="ecsb"></div>
      <div className="container_table">
        <div>
          <h2 className="heading">Select Your Software Preferences</h2>
          <table>
            <thead>
              <tr>
                <th>Software Name</th>
                <th>
                <p>Onboarding</p>
                  <input
                    type="checkbox"
                    checked={selectAllOnboarding}
                    onChange={() => handleSelectAll("onboarding")}
                  />
                  Select All 
                </th>
                <th >
                    <p>Offboarding</p>
                  <input
                    type="checkbox"
                    checked={selectAllOffboarding}
                    onChange={() => handleSelectAll("offboarding")}
                  />
                  Select All 
                </th>
              </tr>
            </thead>
            <tbody>
              {softwareOptions.map(option => (
                <tr key={option.softwareid}>
                  <td>{option.softwarename}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={preferences.find(pref => pref.software_id === option.softwareid)?.onboarding === true}
                      className="checkbox_box"
                      onChange={() => handlePreferenceChange(option.softwareid, 'onboarding')}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="checkbox_box"
                      checked={preferences.find(pref => pref.software_id === option.softwareid)?.offboarding === true}
                      onChange={() => handlePreferenceChange(option.softwareid, 'offboarding')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="button-container">
            <button onClick={submitPreferences} className="submit_btn">Submit Preferences</button>
            <button onClick={cancelSelection} className="cancel_btnlm">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandCollapse;