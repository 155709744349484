import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const SimpleBarChart = ({statsData}) => {
  const data = [
    { month: 'Jan', activeUsers: statsData?.users_accessed, inactiveUsers: statsData?.inactive_users,deactivatedUsers:statsData?.deactivated_users },
    // { month: 'Feb', activeUsers: 3000, inactiveUsers: 1398 },
    // { month: 'Mar', activeUsers: 2000, inactiveUsers: 9800 },
    // Add more data as needed
  ]
  return (
    <div>
      <p style={{color:'white',marginLeft:'60px'}}>Number of licenses : {statsData.number_of_licenses}</p>
  <BarChart width={380} height={300} data={data} >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="month" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="activeUsers" fill="#0088FE" name="Active Users" />
    <Bar dataKey="inactiveUsers" fill="#00C49F" name="Inactive Users" />
    <Bar dataKey="deactivatedUsers" fill="#FFBB28" name="Deactived Users" />
  </BarChart>
    </div>

  )
  };

export default SimpleBarChart;
