// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.homebtn{
    padding: 10px 12px;
    margin-top: 10px;
    font-size: 12px;
    border: 1px solid #642bff;
    cursor: pointer;
    font-weight: bold;
    background-color: hsl(256, 100%, 58%);
    color: white;
    border-radius: 6px;
}
.homebtn:hover{
    background:transparent;
    color: white;
    border: 2px solid #642bff;

}
.confirmation-text{
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Confirmation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,qCAAqC;IACrC,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;;AAE7B;AACA;IACI,YAAY;AAChB","sourcesContent":[".container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100%;\r\n    height: 100vh;\r\n}\r\n.homebtn{\r\n    padding: 10px 12px;\r\n    margin-top: 10px;\r\n    font-size: 12px;\r\n    border: 1px solid #642bff;\r\n    cursor: pointer;\r\n    font-weight: bold;\r\n    background-color: hsl(256, 100%, 58%);\r\n    color: white;\r\n    border-radius: 6px;\r\n}\r\n.homebtn:hover{\r\n    background:transparent;\r\n    color: white;\r\n    border: 2px solid #642bff;\r\n\r\n}\r\n.confirmation-text{\r\n    color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
