import React from 'react'
import { Form,Input,DatePicker,Button,message} from 'antd'
import styles from './AssetForm.module.css'
import axios from 'axios'
const AssetForm = () => {
    const [form] = Form.useForm();
    const id = localStorage.getItem('user_id')
    const onChange = (date, dateString) => {
        console.log(date, dateString);
      };
      const { TextArea } = Input;
      const onFinish = async (values) => {
        console.log('Form data:', values);
        let updated_values = {...values,custid:id};

        try
        {   
            const response = await axios.post('https://vmesas.com:5019/assets',updated_values,{
                headers:{"Content-Type":"application/json"}
            })
            console.log(response);
            if(response.status === 201)
            {
                message.success("Asset Created Succsessfully");
                form.resetFields();
            }

        }
        catch(e)
        {

        }
      };
  return (
    <div>
        <Form form={form} style={{marginTop:"20px",maxHeight:"350px",overflow:"auto",marginRight:"10px"}}  onFinish={onFinish} labelCol={{span:6}} wrapperCol={{ span: 16 }}>
            <Form.Item
                label="Asset ID"
                name="asset_id"
                rules={[
                {
                    required: true,
                    message: 'Please input Asset ID!',
                }]}
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="Asset Name"
                name="asset_name"
                rules={[
                {
                    required: true,
                    message: 'Please input your Name!',
                }]}
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="Category"
                name="category"
                rules={[
                {
                    required: true,
                    message: 'Please input the Category!',
                }]}
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="Manufacturer"
                name="manufacturer"
                rules={[
                {
                    required: true,
                    message: 'Please input the Category!',
                }]}
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="Start Date"
                name="start_date"
                rules={[
                {
                    required: true,
                    message: 'Select the Date!',
                }]}
            >
            <DatePicker onChange={onChange} />
            </Form.Item>
            <Form.Item
                label="Assigned To"
                name="assigned_name"
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="PO No"
                name="po_number"
                rules={[
                {
                    required: true,
                    message: 'Please input PO NO!',
                }]}
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="Invoice"
                name="invoice_number"
                rules={[
                {
                    required: true,
                    message: 'Please input Invoice No!',
                }]}
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="Total Cost"
                name="price"
            >
            <Input/>
            </Form.Item>
            <Form.Item
                label="Retirement Date"
                name="retirement_date"
                rules={[
                {
                    required: true,
                    message: 'Please input Retirement Date!',
                }]}
            >
            <DatePicker onChange={onChange} />
            </Form.Item>
            <Form.Item
                label="Comments"
                name="Comments"
            >
           <TextArea rows={3} />
            </Form.Item>
            <Form.Item style={{display:"flex",justifyContent:"flex-end"}}>
            <Button type="primary" htmlType="submit" style={{marginRight:"10px"}}>
                Submit
            </Button>
            </Form.Item>
        </Form>
    </div>
  )
}

export default AssetForm