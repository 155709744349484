import { createContext ,useState} from "react";
export const CardContext  =  createContext()

function CardProvider({ children }) {
    const [accessCard, setAccessCard] = useState(['UA', 'LM', 'SOD','VCONNECT']);
    const [appStat,setAppStat] = useState([])
    
  console.log("bb28164a529127f8")
    return (
      <CardContext.Provider value={{accessCard,setAccessCard,appStat,setAppStat}}>
          {children}
      </CardContext.Provider>
    );
  } 
export { CardProvider };