import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CardProvider } from './utils/Context/cardContext';
import { UserProvider } from './utils/Context/userContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
    <CardProvider>
      <App />
    </CardProvider>
    </UserProvider>
      
  </React.StrictMode>
);

