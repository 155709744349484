import React from 'react';
import { useState,useEffect } from 'react';
import { Flex,Button,Select,Table,Modal,Popover,message,Result,Card} from 'antd'
import {EditOutlined,UnorderedListOutlined,PlusCircleOutlined,EyeFilled,FilterTwoTone,CheckOutlined,InfoCircleOutlined,ArrowLeftOutlined,SyncOutlined} from '@ant-design/icons';
import styles from "./AssetManagement.module.scss"
import AssetForm from './AssetForm';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import Preview from './Preview';
import {ReactComponent as Validation} from './Images/validation.svg';
import {ReactComponent as Invoice} from './Images/invoice.svg';
import {ReactComponent as ThreeDots} from './Images/threedots.svg';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import {Chart as ChartJS,Tooltip,Legend,ArcElement} from "chart.js";
import ReportsPie from '../ReportsPie';

const AssetManagement = () => {
  ChartJS.register(Tooltip,Legend,ArcElement)
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const [isModalOpen5, setIsModalOpen5] = useState(false);
    const [selectionType, setSelectionType] = useState('radio');
    const [Assigndisable,setAssigndisable]=useState(true);
    const [Chartdata,setChartdata]=useState();
    const [show_creation,setShowCreation]=useState(false);
    const [Unassigndisable,setUnassigndisable]=useState(true);
    const[WorkFlow_data,setWorkFlow_data]=useState();
    const [History_data,setHdata]=useState();
    const [dropdown,setdropdown]=useState();
    const [Tabledata,setTabledata]=useState();
    const [Keys_Data,setKeysData]=useState();
    const [preview_data,setpreviewdata]=useState();
    const [AssignedId,setAssignedId]=useState();
    const [f_data,setFilterData]=useState();
    const [AssetId,setAssetId]=useState();
    const[d_data,setOptions]=useState();
    const[POTableData,setPOTableData]=useState();
    const [type,settype]=useState();
    const [PieData,setPieData]=useState();
    const id = localStorage.getItem('user_id');
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(selectedRows)
        console.log('selectedRows: ', selectedRows[0].assigned_to);
        setSelectedRowKeys(selectedRowKeys);
        setAssignedId(selectedRows[0].assigned_to);
        setAssetId(selectedRows[0].asset_id)
        if(selectedRows[0].assigned_to)
        {
          setAssigndisable(true);
          setUnassigndisable(false);
        }
        else
        {
          setAssigndisable(false);
          setUnassigndisable(true);
        }
      }
    }
    

    const rowSelection1 = 
    {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {}
    }

    const handle_filter = (value)=>
    {
      console.log(type)
      console.log(value)
      const set_filtereddata = f_data.filter((item)=>(item[type]===value));
      setTabledata(set_filtereddata)
    }

    const type_options =[
      {
        value: 'status',
        label: 'Status',
      },
      {
        value: 'category',
        label: 'Category',
      }
    ]
    
    const status_options = [
      {
        value:'Active',
        label:'Active'
      },
      {
        value:'Inactive',
        label:'Inactive'
      }
    ]


    const showModal = () => {
        setIsModalOpen(true);
      };
    const handleCancel = () => {
        setIsModalOpen(false);
        fetch_data();
      };
      const showModal1 = () => {
        setIsModalOpen1(true);
      };
    const handleCancel1 = () => {
        setIsModalOpen1(false);
      };
      const showModal2 = () => {
        setIsModalOpen2(true);
      };
    const handleCancel2 = () => {
        setIsModalOpen2(false);
      };
      const showModal3 = () => {
        setIsModalOpen3(true);
      };
    const handleCancel3 = () => {
        setIsModalOpen3(false);
      };
      const showModal4 = () => {
        setIsModalOpen4(true);
      };
    const handleCancel4 = () => {
        setIsModalOpen4(false);
      };
      const showModal5 = () => {
        setIsModalOpen5(true);
      };
    const handleCancel5 = () => {
        setIsModalOpen5(false);
      };
    const columns = [
        // {
        //   title: 'Serial No',
        //   dataIndex: 'SerialNo',
        //   // key: 'Serial No',
        //   align:'center'
        // },
        {
          title: 'Asset ID',
          dataIndex: 'asset_id',
          key: 1,
          align:'center'
        },
        {
          title: 'Name',
          dataIndex: 'assigned_name',
          key: 2,
          align:'center'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 3,
            align:'center'
        },
        {
            title: 'Assigned To',
            dataIndex: 'assigned_to',
            key: 4,
            align:'center'
        },
        {
          title: 'Status',
          dataIndex: 'status',  
          key: 5,
          align:'center'
      },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 6,
            align:'center',
            render: (text,record) => (
              <Flex justify='center' align='center'>
                  <EyeFilled onClick={() => handle_preview(record.asset_id)} />
                  <ThreeDots onClick={()=>{handle_history(record.asset_id)}} style={{height:"16px",cursor:"pointer"}}/>
              </Flex>
          ),
        }
      ];
      const defaultCheckedList = columns.map((item) => item.key);
      console.log(defaultCheckedList)
      const [checkedList, setCheckedList] = useState(defaultCheckedList);
      const configure_options = columns.map(({ key, title }) => ({
        label: title,
        value: key,
      }));
      const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key),
      }));
      const PO_columns = [
        {
          title: 'PO Number',
          dataIndex: 'PO Number',
          // key: 'Serial No',
          align:'center'
        },
        {
          title: 'AM',
          dataIndex: 'AM',
          // key: 'Serial No',
          align:'center'
        },
        {
          title: 'ERP',
          dataIndex: 'ERP',
          // key: 'Serial No',
          align:'center'
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          // key: 'Serial No',
          align:'center'
        }
      ]

      const h_columns = [
        {
          title: 'Assigned Date',
          dataIndex: 'assigned_date',
          align:'center',
          width: 500
        },
        {
          title: 'Assigned To',
          dataIndex: 'assigned_to',
          align:'center'
        },
        {
          title: 'Assignment ID',
          dataIndex: 'assignment_id',
          align:'center'
        },
        {
          title: 'Customer ID',
          dataIndex: 'custid',
          align:'center'
        },
        {
          title: 'Unassigned Date',
          dataIndex: 'unassigned_date',
          align:'center'
        }
      ]
      

    const work_columns = [
      {
        title: 'Workflow ID',
        dataIndex: 'Workflow ID',
        align:'center',
      },
      {
        title: 'Description',
        dataIndex: 'Description'
      }

    ]

    const handle_workflow = async()=>
    {
      try{
        const response = await axios.get('https://vmesas.com:5024/data')
        console.log(response);
        if(response.status===200){
        setWorkFlow_data(response.data);
        showModal4();}
      }
      catch(e){

      }
    }
    const handle_history = async(a_id)=>
    {
      console.log(a_id)
      try
      {
        const response = await axios.get('https://vmesas.com:5019/assets',{params:{custid:id}})
        const assets = response.data; // accessing the data property of the response object
        const history_data = assets.filter((item) => (item.asset_id === a_id));
        console.log(history_data[0].assignment_history);
        setHdata(history_data[0].assignment_history);
        showModal3();
      }
      catch(e)
      {

      }
    }

      const handle_PO = async()=>
      {
        console.log("clicked")
        try
        {
          const response = await axios.get('https://vmesas.com:5021/compare_po_counts',{params:{custid:id}});
          console.log(response.data)
          setPOTableData(response.data)
          showModal2();
        }
        catch(e)
        {
          console.log(e)
        }
      }


      const handle_preview = (id)=>
      {
        const filteredArray = Keys_Data.filter(item => {
          const keys = Object.keys(item);
          return keys.includes(id);
      });
      const data = filteredArray[0];
      const val = Object.values(data)
      setpreviewdata(Object.values(data));
      console.log(Object.values(data));
      //console.log(Object.entries(val));
      showModal1()
      }

      const dropdowndata = (value)=>
      {
        settype(value)
        if(value === "status")
        {
          setdropdown(status_options);
        }
        else
        {
          setdropdown([{value:'Electronics',label:'Electronics'},{value:'Office Equipment',label:'Office Equipment'}])
        }

      }
      
      
      const handle_unassign = async()=>
      {
        const asset_id={"asset_id":AssetId};
        const headers = {
          'Content-Type': 'application/json'
        };
        try
        {
          const response = await axios.post('https://vmesas.com:5019/unassign',asset_id,{ headers: headers });
          console.log(response);
          fetch_data();
          message.success("Unassigned Successfully");
          setSelectedRowKeys([])
        }
        catch(e)
        {
          console.log(e);
        }
      }
      var pie_chartdata={};
      const handle_reportsanddashboard = async()=>
      {
        try
        {
          const response = await axios.get ('https://vmesas.com:5023/assets/reports');
          console.log(response.data)
          setPieData(response.data)
          showModal5();
          
         
        }
        catch(e)
        {
          console.log(e)
        }
      }
      useEffect(()=>{console.log(Chartdata)},[Chartdata])
      const assign_asset = async (value)=>
      {
        const data = {asset_id:AssetId,employee_id:value}
        console.log(data);
        try
        {
          const response = await axios.post('https://vmesas.com:5019/assign',data);
          console.log(response)
          fetch_data();
          message.success("Asset Assigned To User");
          setSelectedRowKeys([])
        }
        catch(e)
        {
          console.log(e)
        }
      }

      const options={};
      useEffect(()=>{
        
        fetch_data();
      },[])
      
      const fetch_data = async ()=>
      {
        try
        {
          const response = await axios.get('https://vmesas.com:5019/assets',{params:{custid:id}})
          console.log(response)
          console.log(id)
          //console.log(data)
          const datasource = response.data.map((item) => {
            // console.log(index)
            return {
                asset_id: item.asset_id,
                assigned_name: item.asset_name,
                assigned_to: item.assigned_name,
                category: item.category,
                status: item.status
            };
          });
          const for_preview_data = response.data.map((item) => {
            const id = item.asset_id;
            return {
                [id]: item
            };
          });
        console.log(for_preview_data);
        setKeysData(for_preview_data);
        setTabledata(datasource);
        setFilterData(datasource);
        setAssigndisable(true);
        setUnassigndisable(true);         
        }
        catch(e)
        {
          console.log(e);
        }
      }
      useEffect(()=>{
        const fetch_assigness = async ()=>
        {
          try
          {
            const response = await axios.get('https://vmesas.com:5019/employees',{params:{custid:id}});
            console.log(response.data);
            const options = response.data.map(item => ({
              label: item.employee_name,
              value: item.employee_id
            }));
            setOptions(options);
          }
          catch(e)
          {
            console.log(e)
          }
        }
        fetch_assigness();
      },[])

      const content = (
        <Flex vertical gap={"small"}>
          <Button onClick={handle_PO} style={{margin:"0px",cursor: "pointer",backgroundColor:"#8DECB4",display:"flex",alignItems:"center"}}><Validation style={{height:"25px",width:"25px",paddingRight:'10px'}}/>Validate PO</Button>
          <Button style={{margin:"0px",cursor: "pointer",display:"flex",alignItems:"center"}}><Invoice style={{height:"25px",width:"25px",paddingRight:'10px'}}/>Validate Invoice</Button>
        </Flex>
      );
      const content1 = (
        <Flex vertical gap={"small"}>
          <Button style={{margin:"0px",cursor: "pointer",display:"flex",alignItems:"center"}}>ERP</Button>
          <Button style={{margin:"0px",cursor: "pointer",display:"flex",alignItems:"center"}}>Octa</Button>
          <Button style={{margin:"0px",cursor: "pointer",display:"flex",alignItems:"center"}}>HRIS</Button>
        </Flex>
      );
  return (
    <div style={{height:"100vh",width:"100%",backgroundColor:"white"}}>
    {/* <div style={{height:"50px",backgroundColor:"gray", backgroundImage: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)"}}><h2 style={{margin:"0px",textAlign:"center"}}>VMESAS</h2></div> */}
    <Flex vertical gap={"50px"}>
        <Flex justify='space-between'>
        <Button style={{marginTop:"10px"}} onClick={()=>{navigate('/authpage')}}><ArrowLeftOutlined />Homepage</Button>
        <div>
        <h2 className={styles.Heading}>Asset Management</h2>
        </div>
        <div style={{display:"flex",gap:"10px",marginTop:"10px"}}>
        <Popover content={content1}  trigger="click" placement="bottom">
        <Button style={{backgroundColor:"#A0DEFF"}}>Integrate</Button>
        </Popover>
        <Button style={{backgroundColor:"#A0DEFF"}} onClick={()=>{handle_workflow()}}>WorkFlow</Button>
        <Button style={{backgroundColor:"#A0DEFF"}} onClick={()=>{handle_reportsanddashboard()}}>Reports And Dashboard</Button>
        </div>
        </Flex>
        <Flex style={{marginLeft:"30px",marginRight:"30px"}} justify='space-between'>
            <Flex justify='flex-end'></Flex>
            <Flex gap={"large"}>
                {/* <Button icon={<EditOutlined />}>Edit</Button> */}
                <Button icon={<PlusCircleOutlined />} onClick={showModal} style={{backgroundColor:"#8576FF",color:"white"}}>Add New Asset</Button>
                <Select disabled={Assigndisable} placeholder="Assign To" style={{width:"150px"}} options={d_data} onSelect={(value)=>{assign_asset(value)}}/>
                <Button disabled={Unassigndisable} placeholder="Unassign" style={{width:"100px"}} onClick={()=>{handle_unassign()}}>Unassign</Button>
                <Select disabled={Unassigndisable} placeholder="Assign Softwares" style={{width:"150px"}} options={d_data} onSelect={(value)=>{assign_asset(value)}}/>
            </Flex>
            <Flex gap={"large"}>
                <Select className={styles.Type} placeholder={<span style={{fontWeight:600}}>Selection Type</span>} style={{width:"150px"}} options={type_options} onSelect={(value)=>{dropdowndata(value)}}/>
                <Select className={styles.Type} placeholder={<><FilterTwoTone /> <span style={{fontWeight:600}}>Filter</span></>} style={{width:"150px"  }} icon={<EditOutlined/>} options={dropdown} onSelect={(value)=>handle_filter(value)}/>
                <Popover content={content}  trigger="click" placement="bottom">
                    <Button style={{backgroundColor:"#BACD92"}}><CheckOutlined />Validate</Button>
                </Popover>
            </Flex>
        </Flex>
        <Flex style={{marginLeft:"30px"}} gap={"small"}>
        <Select mode="multiple" value={checkedList} options={configure_options} placeholder="Configure Fields" style={{width:"200px",maxHeight:"50px",overflow:"auto"}} onChange={(value) => {setCheckedList(value);}}></Select>
        <Button onClick={()=>{setCheckedList(defaultCheckedList)}}><SyncOutlined /></Button>
        </Flex>
        <Table style={{width:"95%",marginLeft:"30px"}} columns={newColumns} rowSelection={{type:selectionType,...rowSelection}} rowKey="asset_id" className={styles.AssetTable} dataSource={Tabledata}/>
    </Flex>
    <Modal styles={{padding:"20px"}} open={isModalOpen} footer={null} onCancel={handleCancel}><AssetForm/></Modal>
    {preview_data&&<Modal open={isModalOpen1} footer={null} onCancel={handleCancel1} className={styles.PModal}>
    {/* {Object.entries(preview_data[0]).map(([key, value], index) => (
            <div key={index}>
                <span className={styles.label}>{capitalizeFirstLetter(key)}: </span>
                <span className={styles.value}>{value}</span>
            </div>
    ))} */}
    {console.log(preview_data[0])}
    <Preview data={preview_data[0]}/>
    </Modal>}
    {POTableData&&<Modal styles={{padding:"20px"}} open={isModalOpen2} footer={null} onCancel={handleCancel2}><Table dataSource={POTableData} columns={PO_columns} pageSize={5}/></Modal>}
    {History_data&&<Modal open={isModalOpen3} width={800} footer={null} onCancel={handleCancel3}><Table  className={styles.History} dataSource={History_data} columns={h_columns} pageSize={5}/></Modal>}
    {WorkFlow_data&&<Modal open={isModalOpen4} width={800} onCancel={handleCancel4}><Table rowSelection={{rowSelection1}}dataSource={WorkFlow_data} columns={work_columns} pageSize={5} rowKey="Workflow ID" /></Modal>}
    {PieData&&<Modal open={isModalOpen5} width={800} onCancel={handleCancel5} footer={null}>
      <Flex>
      <ReportsPie statsData={PieData}/>
      <Card
      title="Your Assets"
      style={{
      width: 300,
      }}
    >
      <span style={{display:"flex",gap:"5px"}}><p style={{fontWeight:"600"}}>Assigned Assets:</p><p>{PieData.assigned_assets}</p></span>
      <span style={{display:"flex",gap:"5px"}}><p style={{fontWeight:"600"}}>Lost Assets:</p><p>{PieData.lost_assets}</p></span>
      <span style={{display:"flex",gap:"5px"}}><p style={{fontWeight:"600"}}>Retired Assets:</p><p>{PieData.retired_assets}</p></span>
      <span style={{display:"flex",gap:"5px"}}><p style={{fontWeight:"600"}}>Total Assets:</p><p>{PieData.total_assets}</p></span>
      <span style={{display:"flex",gap:"5px"}}><p style={{fontWeight:"600"}}>Unassigned Assets:</p><p>{PieData.unassigned_assets}</p></span>
    </Card>
      </Flex>
      </Modal>
    } 
    </div>
  )
}

export default AssetManagement