import React, { useContext } from 'react';
import login from '../login.png';
import { useNavigate } from 'react-router-dom';
import './Signin.css';
import { Formik } from 'formik';
import axios from 'axios';
import { loginSchema } from '../utils/validate';
import { showToast } from '../utils/toast';
import { CardContext } from '../utils/Context/cardContext';
import { UserContext } from '../utils/Context/userContext';

const Signin = () => {
  const navigate = useNavigate();
  const { setAccessCard } = useContext(CardContext);
  const { setUser, setIsAuthenticated, setUserEmail } = useContext(UserContext);

  const initialValues = {
    email: '',
    password: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={(values) => {
        axios
          .post('https://vmesas.com:5000/login', { email: values.email, password: values.password })
          .then((res) => {
            console.log(res.data);
            setAccessCard(res.data.subscribe);
            setUser(res.data.user_id);
            setUserEmail(res.data.email);
            localStorage.setItem('user_id', res.data.user_id);
            showToast('Login successful!');
            setIsAuthenticated(true);
            navigate('/authpage');
          })
          .catch((err) => {
            console.log(err);
          });
        console.log(values);
      }}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur
        } = formik;
        return (
          <div className='iumaidiv'>
            <img src={login} className='limg' alt='login' />
            <form className='form2' action='#' onSubmit={handleSubmit}>
              <h1 className='h1iu'>Sign in</h1>
              <div className='social-container'>Welcome Back</div>
              <div>
                <input
                  className='inputiu'
                  type='email'
                  placeholder='Email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='email'
                />
                {errors.email && touched.email ? (
                  <p className='errorMsg'>{errors.email}</p>
                ) : null}
              </div>
              <div>
                <input
                  className='inputiu'
                  type='password'
                  placeholder='Password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='password'
                />
                {errors.password && touched.password ? (
                  <p className='errorMsg'>{errors.password}</p>
                ) : null}
              </div>
              <a className='aiu' href='#'>
                Forgot your password?
              </a>
              <button className='buttoniu' type='submit'>
                Sign In
              </button>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Signin;
