import React from 'react'
import styles from './Preview.module.css'
import { Divider,Flex,Col,Row } from 'antd';
import {ReactComponent as Bigdata} from './Images/big-data.svg';
import {ReactComponent as Finance} from './Images/finance.svg';
import {ReactComponent as User} from './Images/user.svg';
import {ReactComponent as Coding} from './Images/coding.svg';
import {ReactComponent as Deadline} from './Images/deadline.svg';
const Preview = ({data}) => {
console.log(data);
  return (
    <div style={{marginTop:"20px",maxHeight:"350px",overflow:"auto",marginRight:"10px"}}>
        <Divider><Flex align='center'><Bigdata style={{height:"25px",width:"25px",paddingRight:'10px'}}/>Asset Details</Flex></Divider>
        <Row>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Asset ID:</span>{data.asset_id}</Col>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Category:</span>{data.category}</Col>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Manufacturer:</span>{data.manufacturer}</Col>
        </Row>
        <Row>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Serial No:</span>{data.sno}</Col>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Status:</span>{data.status}</Col>
        </Row>
        <Divider><Flex align='center'><Finance style={{height:"25px",width:"25px",paddingRight:'10px'}}/>Financial Information</Flex></Divider>
        <Row>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Price:</span>{data.price}</Col>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Invoice No:</span>{data.invoice_number}</Col>
            <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>PO Number:</span>{data.po_number}</Col>
        </Row>
        <Divider><Flex align='center'><User style={{height:"25px",width:"25px",paddingRight:'10px'}}/>Assigned Information</Flex></Divider>
        <Row>
            <Col span={12}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Assigned Name:</span>{data.assigned_name}</Col>
            <Col span={12}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Assigned To:</span>{data.assigned_to}</Col>
        </Row>
        <Row>
        <Col span={8}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>User_ID:</span>{data.user_id}</Col>
        </Row>
        
        <Divider><Flex align='center'><Coding style={{height:"25px",width:"25px",paddingRight:'10px'}}/>Software Information</Flex></Divider>
        <Row>
            <Col span={24}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Softwares Assigned:</span>{data.softwares_assigned}</Col>
        </Row>
        <Divider><Flex align='center'><Deadline style={{height:"25px",width:"25px",paddingRight:'10px'}}/>Dates</Flex></Divider>
        <Row>
            <Col span={12}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Start Date:</span>{data.start_date}</Col>
            <Col span={12}><span style={{fontWeight:"600",color:"blue",paddingRight:"3px"}}>Retirement Date:</span>{data.retirement_date}</Col>
        </Row>

    </div>
  )
}

export default Preview