// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_div{
    height: 90vh;
    width: 100%;
    background-color: #3C5B6F;

}
.card_icon{
  font-size: 100px;
}
.card_text{
    font-weight: 500;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}
.heading{
  color: white;
  text-align: center;
  margin: 0px;
}
.card_wrapper{
    display: flex;
    padding: 40px;
    justify-content: space-between;
    margin-top: 60px;
}
.card_s{
    width: 220px;
    height: 300px;
    border: 1px solid #642bff;
    /* background-color: #642bff; */
    border-radius: 6px;
    color: white;
    text-align: center;
    
}
.card_content{
    font-size: 18px;
}

.card-heading
{
    font-size: 28px;
    font-weight: 700;
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(152,148,233,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Data
{
    font-weight: 600;
    
}`, "",{"version":3,"sources":["webpack://./src/components/Solutions.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;;AAE7B;AACA;EACE,gBAAgB;AAClB;AACA;IACI,gBAAgB;IAChB;AACJ;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;AACA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,+BAA+B;IAC/B,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;;AAEtB;AACA;IACI,eAAe;AACnB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;IAChB,qFAAqF;IACrF,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;;IAEI,gBAAgB;;AAEpB","sourcesContent":[".main_div{\r\n    height: 90vh;\r\n    width: 100%;\r\n    background-color: #3C5B6F;\r\n\r\n}\r\n.card_icon{\r\n  font-size: 100px;\r\n}\r\n.card_text{\r\n    font-weight: 500;\r\n    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif\r\n}\r\n.heading{\r\n  color: white;\r\n  text-align: center;\r\n  margin: 0px;\r\n}\r\n.card_wrapper{\r\n    display: flex;\r\n    padding: 40px;\r\n    justify-content: space-between;\r\n    margin-top: 60px;\r\n}\r\n.card_s{\r\n    width: 220px;\r\n    height: 300px;\r\n    border: 1px solid #642bff;\r\n    /* background-color: #642bff; */\r\n    border-radius: 6px;\r\n    color: white;\r\n    text-align: center;\r\n    \r\n}\r\n.card_content{\r\n    font-size: 18px;\r\n}\r\n\r\n.card-heading\r\n{\r\n    font-size: 28px;\r\n    font-weight: 700;\r\n    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(152,148,233,1) 100%);\r\n    -webkit-background-clip: text;\r\n    -webkit-text-fill-color: transparent;\r\n}\r\n\r\n.Data\r\n{\r\n    font-weight: 600;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
