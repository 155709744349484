import React,{useState} from 'react';
import './Signup.css'
import signup from '../signup.png'
import { SignUpSchema } from '../utils/validate';
import { Formik } from 'formik';
import { showToast } from '../utils/toast';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Signup = () => {
	

	const initialValues = {
		customerName:"",
		contactPerson:"",
		email:"",
		phoneNumber:""
	}

	const navigate = useNavigate()

  return (
	<Formik
	initialValues={initialValues}
	validationSchema={SignUpSchema}
	onSubmit={(values) => {
		
		axios.post('https://vmesas.com:5001/signup',{CustomerName:values.customerName,ContactPerson:values.contactPerson,Email:values.email,Phone:values.phoneNumber}).then((res)=>{
			console.log(res)
            navigate('/confirmation')
			
			
		}).catch((err)=>{
			console.log(err)
		})
		console.log(values)
	  }}
  >
  {
(formik)=>{
	  const {
		values,
		handleChange,
		handleSubmit,
		errors,
		touched,
		handleBlur,
		
	  } = formik;
	  return (

    <div className='iumaidiv1'>
		<ToastContainer/>
   <img src={signup} className='simg'/>
		<form className='formu' action="#" onSubmit={handleSubmit}>
			<h1 className='h1iu'>Create Your Account</h1>
			<div class="social-container">
				You are one step ahead to VMESAS!!
			</div>
			<div>
            <input 
			className='inputiu' 
			type="text"
			placeholder="Customer Name" 
			value={values.customerName}
            onChange={handleChange}
            onBlur={handleBlur}
            name="customerName" 
			 />
			  { errors.customerName && touched.customerName ?    <p className="errorMsg">{errors.customerName}</p>:null}
			</div>
			
				<div>

            <input 
			className='inputiu' 
			type="text" 
			placeholder="Contact Person"
			value={values.contactPerson}
            onChange={handleChange}
            onBlur={handleBlur}
            name="contactPerson" 

			 />
			  { errors.contactPerson && touched.contactPerson ?    <p className="errorMsg">{errors.contactPerson}</p>:null}
				</div>
				<div>

			<input 
			className='inputiu' 
			type="email" 
			placeholder="Email"
			value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            name="email" 
			 />
			  { errors.email && touched.email ?    <p className="errorMsg">{errors.email}</p>:null}
				</div>
				<div>
			<input 
			className='inputiu' 
			type="text" 
			placeholder="Phone number" 
			value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            name="phoneNumber" 
			/>
			 { errors.phoneNumber && touched.phoneNumber ?    <p className="errorMsg">{errors.phoneNumber}</p>:null}
				</div>
			
			<button className='buttoniu' type="submit">Sign Up</button>
		</form>
	
   </div>
	  )
}
}
</Formik>
  )
}

export default Signup