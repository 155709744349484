import { Navigate, useNavigate } from 'react-router-dom';
import './Confirmation.css';
const Confirmation = () =>{
    const navigate = useNavigate()
    return (
        <div className='container'>
            <h1 className='confirmation-text'>We will get back to you soon.</h1>
            <button onClick={()=>navigate('/')} className='homebtn'> Homepage</button>
        </div>
    )
}

export default Confirmation