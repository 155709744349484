import React, { useContext } from 'react'
import './Cardd.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CardContext } from '../utils/Context/cardContext';

const Cardd = (props) => {
  const navigate = useNavigate();
  // const {setAppStat} = useContext(CardContext)
  // const handleButtonClick =  (name) => {
  //    axios.get(`https://vmesas.com:5005/getAppStats?appName=${name}`).then((res)=>{
  //     console.log(res.data)
  //     setAppStat(res.data)
  //     navigate('/Ascreen');
    
  //    }).catch((err)=>{
  //     console.log(err)
  //    })

  // };

  return (
    <div class="cardd">
  <img className="cimg" src={props.name}/>
  <button className='arbtn' onClick={()=>navigate('/SOD')}>></button>
  
  </div>
  )
}

export default Cardd