import { color } from 'framer-motion';
import { PieChart, Pie, Tooltip, Legend ,Cell} from 'recharts';



const SimplePieChart = ({statsData}) => {
  console.log(statsData)
  const data = [
    { name: 'Active Users', value: statsData?.users_accessed, color: '#0088FE' },
    { name: 'Inactive Users', value: statsData?.inactive_users, color: '#00C49F' },
    { name: 'Deactivated Users', value: statsData?.deactivated_users, color: '#FFBB28' },
  ];
  return (
  <PieChart width={380} height={300}>
    <Pie
      data={data}
      cx={200}
      cy={150}
      innerRadius={60}
      outerRadius={80}
      dataKey="value"
      nameKey="name"
      label
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.color} />
      ))}
    </Pie>
    <Tooltip  />
    <Legend color='#fff' fill='#fff' />
  </PieChart>
  )
  };

export default SimplePieChart;
