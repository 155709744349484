import React from 'react'
import ExpandCollapse from './ExpandCollapse'
import './Useradmin.css'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
const Useradmin = () => {
  return (
    <div className='mmm'>
          <div className="navbar1">
        <FaArrowLeft/> <Link to="/authpage" className="home_link">Homepage</Link>
        </div>
    <div className='mainuser'>
      <ExpandCollapse/>
    </div>
    </div>
  )
}

export default Useradmin