// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainuser
{
  background-color: #3C5B6F;
  height:100%;
  width:100%;

}
.mmm
{
  height: 100vh;
  width:100%;
  /* background-color: #000000; */
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Useradmin.css"],"names":[],"mappings":"AAAA;;EAEE,yBAAyB;EACzB,WAAW;EACX,UAAU;;AAEZ;AACA;;EAEE,aAAa;EACb,UAAU;EACV,+BAA+B;EAC/B,YAAY;AACd","sourcesContent":[".mainuser\r\n{\r\n  background-color: #3C5B6F;\r\n  height:100%;\r\n  width:100%;\r\n\r\n}\r\n.mmm\r\n{\r\n  height: 100vh;\r\n  width:100%;\r\n  /* background-color: #000000; */\r\n  color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
