import './Solutions.css'
import { AiFillCopy } from "react-icons/ai";
import { AiFillCloud } from "react-icons/ai";
import { FaUserShield } from "react-icons/fa";
import { TbArrowsJoin } from "react-icons/tb";
import { Card } from 'antd';

const Solutions = () =>{
    return (
        <div className="main_div" id="solution_div">
           <h1 className='heading'>Solutions</h1>
           <div className='card_wrapper'>
            <Card
                title={<span className='card-heading'>License <br/>Management</span>}
                style={{maxWidth:"250px"}}
            >
                <p className='Data'>License Management provides insights on the application usage to take the necessary action.</p>
            </Card>

            <Card
                title={<span className='card-heading'>Segregation <br/> Of Duties</span>}
                style={{maxWidth:"250px"}}
            >
                <p className='Data'>Segregation of duties provide the key insights of violations in role management.</p>
            </Card>
            
            <Card
                title={<span className='card-heading'>User <br/>Administration</span>}
                style={{maxWidth:"250px"}}
            >
                <p className='Data'>Our IDM will help the automation of user administration and Role Management. Seamless Onboarding and offboarding experience for the users. </p>
            </Card>

            <Card
                title={<span className='card-heading'>Integration <br/> Capability</span>}
                style={{maxWidth:"250px"}}
            >
                <p className='Data'>VMESAS integration helps to integrate the Enterprise applications.</p>
            </Card>


           </div>
        </div>
    )
}

export default Solutions