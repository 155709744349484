// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_table { 
width: 100%;
display: flex;
justify-content: center;
height: 100%;
}

table { 
  width: 100%; 
  border-collapse: collapse; 
}
.cancel_btnlm{
  padding: 5px 16px;
  background-color: #BF3131;
  border: none;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 20px;
}

.radio_box{
  cursor:pointer;
}
th, td { 
  border: 1px solid #ddd; 
  padding: 8px; 
  text-align: left; 
}

.heading{
  margin-bottom: 20px;
}
p{
  margin:12px 0px 10px;

}

th { 
  
  border:1px solid #642bff;
}


.button-container { 
  text-align: center; 
  margin-top: 20px;
}

button { 
  cursor: pointer; 
}`, "",{"version":3,"sources":["webpack://./src/components/ExpandCollapse.css"],"names":[],"mappings":"AAAA;AACA,WAAW;AACX,aAAa;AACb,uBAAuB;AACvB,YAAY;AACZ;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,oBAAoB;;AAEtB;;AAEA;;EAEE,wBAAwB;AAC1B;;;AAGA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container_table { \r\nwidth: 100%;\r\ndisplay: flex;\r\njustify-content: center;\r\nheight: 100%;\r\n}\r\n\r\ntable { \r\n  width: 100%; \r\n  border-collapse: collapse; \r\n}\r\n.cancel_btnlm{\r\n  padding: 5px 16px;\r\n  background-color: #BF3131;\r\n  border: none;\r\n  color: white;\r\n  border-radius: 2px;\r\n  cursor: pointer;\r\n  margin-left: 20px;\r\n}\r\n\r\n.radio_box{\r\n  cursor:pointer;\r\n}\r\nth, td { \r\n  border: 1px solid #ddd; \r\n  padding: 8px; \r\n  text-align: left; \r\n}\r\n\r\n.heading{\r\n  margin-bottom: 20px;\r\n}\r\np{\r\n  margin:12px 0px 10px;\r\n\r\n}\r\n\r\nth { \r\n  \r\n  border:1px solid #642bff;\r\n}\r\n\r\n\r\n.button-container { \r\n  text-align: center; \r\n  margin-top: 20px;\r\n}\r\n\r\nbutton { \r\n  cursor: pointer; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
