import { SiBookstack } from "react-icons/si";
import './CaseStudies.css'
import { Card, Timeline , Flex } from "antd";
const CaseStudies = () => {
    return (
        <div className="main_div" id="casestudy_div">   
            {/* <h1 className="heading">Case Studies</h1>
            
            <div className='card_wrapper'>
                <div className='card_s'>
                    <p className='card_content'>Case Study</p>
                    <i className='card_icon'><SiBookstack/></i>
                    <p className='card_text'>One of the real estate customer in India saved 20 % of their License fee using Licese Management </p>
                </div>

                <div className='card_s'>
                    <p className='card_content'>Case Study</p>
                    <i className='card_icon'><SiBookstack/></i>
                     <p className='card_text'>Client from Middle East automated their User and Role administration using our Onboarding application </p>
                </div>

                <div className='card_s'>
                    <p className='card_content'>Case Study</p>
                    <i className='card_icon'><SiBookstack/></i>
            <p className='card_text'>Client from India using our SOD to identify the risks in their role management</p>
                </div>


            </div> */}
            <Card style={{paddingTop:"50px"}}>
                <Flex gap={100}>
                <span style={{fontSize:"32px",textAlign:"center"}}className="card-heading">Unlocking Success Stories with VMESAS Solutions</span>
                <Timeline
                    items={[
                    {children: 'One of the real estate customer in India saved 20 % of their License fee using License Management.'},
                    {children: 'Client from Middle East automated their User and Role administration using our Onboarding application.'},
                    {children: 'Client from India using our SOD to identify the risks in their role management.'}
                ]}
                />
                </Flex>
            </Card>
            
        </div>
    )
}

export default CaseStudies