
import './Table.css'
import { Table as BootstrapTable} from 'react-bootstrap';
import { Modal,Table } from 'antd';
import { useState } from 'react';
import axios from 'axios';
const ResultTable = ({statsData}) =>{
  const columns = [
    {
      title: 'sno',
      dataIndex: 'sno',
      key: 'sno',
      align:'center'
    },
    {
      title: 'uid',
      dataIndex: 'uid',
      key: 'uid',
      align:'center'
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      align:'center',
      filters: [
        {
          text: '@gmail.com',
          value: '@email.com',
        },
        {
          text: '@example.com',
          value: '@example.com',
        },
      ],
      onFilter: (value, record) => record.email.indexOf(value) > -1,
      sorter: (a, b) => {
        const domainA = a.email.substring(a.email.indexOf('@'));
        const domainB = b.email.substring(b.email.indexOf('@'));
        return domainA.localeCompare(domainB);
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Last Accessed',
      dataIndex: 'last_accessed',
      key: 'last_accessed',
      align:'center'
    },
  ]
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [Inactive,setInactiveData]=useState();
  const showModal1 = () => {
    setIsModalOpen1(true);
  };
const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const handle_inactive = async()=>
  {
    try{
      const response = await axios.get('https://vmesas.com:5022/api/data')
      console.log(response);
      if(response.status===200){
      setInactiveData(response.data);
      showModal1();
      }
    }
    catch(e){

    }
  }
       return (
        <div className='result_table'>
             <BootstrapTable striped  hover>
      <thead>
        <tr style={{color:"black"}}>
 
          <th>Active Users</th>
          <th>Inactive Users</th>
          <th>Deactivated Users</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{color:"black"}}>
   
          <td>{statsData?.users_accessed}</td>
          <td>{<span style={{cursor:"pointer"}} onClick={()=>{handle_inactive()}}>{statsData?.inactive_users}</span>}</td>
          <td>{statsData?.deactivated_users}</td>
        </tr>
       
      </tbody>
    </BootstrapTable>
    {Inactive&&<Modal width={800} styles={{padding:"20px"}} open={isModalOpen1} footer={null} onCancel={handleCancel1}>
    <Table style={{width:"95%",marginLeft:"30px"}} columns={columns} dataSource={Inactive} pageSize={5}/>
      </Modal>}
        </div>
       )
}

export default ResultTable