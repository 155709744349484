import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import logo from "../websitelogo.png";
import configure from "../configure.png";
import dropdown from "../dropdown.png";
import { scrollSection, solutionRef } from "../utils/helper";
import { TbLicense } from "react-icons/tb";
import { AiOutlineSolution } from "react-icons/ai";


const Navbar = () => {
  const navigate = useNavigate();  
  const handleScroll = (name)=>{
      const element = document.getElementById(name)
    
      if(element){
        console.log("hh")
        element.scrollIntoView({behavior:'smooth'})
      }
  }
  return (
    <div styles={{marginTop:"10px"}}>
      <nav className="navbar">
        <h1 className="logo-title">VMESAS</h1>
        <ul className="navbar-links">
          <div className="ddiv">
            <li className="navbar-dropdown">
              <a href="#">Home</a>
            </li>
          </div>
         
          <div>
            <li className="navbar-dropdown">
              <a href="#">
                Solutions{" "}
                <img src={dropdown} alt="License Management" className="down" />
              </a>
              <div className="dropdown">
               
                <div className="dropdown_link" onClick={()=>handleScroll('solution_div')}>
         
                <a onClick={()=>handleScroll('service_div')} > License Management</a>
                </div>
                <div className="dropdown_link" onClick={()=>handleScroll('solution_div')}>
               
                <a onClick={()=>handleScroll('service_div')} > SOD</a>
                </div>
                <div onClick={()=>handleScroll('solution_div')}  className="dropdown_link">
                <a onClick={()=>handleScroll('service_div')}  className="dropdown_link">  User Administration</a>
                </div>
                <div onClick={()=>handleScroll('solution_div')} className="dropdown_link" >
                <a onClick={()=>handleScroll('service_div')}  className="dropdown_link"> Integration</a>
                </div>
              </div>
            </li>
          </div>
          <div>
            <li className="navbar-dropdown">
              <a href="#">
                Services{" "}
                <img src={dropdown} alt="License Management" className="down" />
              </a>
              <div className="dropdown">
                <div className="dropdown_link" onClick={()=>handleScroll('service_div')}>
                <a onClick={()=>handleScroll('service_div')} >License Audit</a>
                </div>
              <div className="dropdown_link" onClick={()=>handleScroll('service_div')}>

                <a  onClick={()=>handleScroll('service_div')} >ITGC Audit</a>
              </div>
              <div className="dropdown_link" onClick={()=>handleScroll('service_div')}>
                <a onClick={()=>handleScroll('service_div')} >Business Process Optimization</a>
              </div>
              </div>
            </li>
          </div>
          <div>
            <li className="navbar-dropdown">
              <a href="#">
                CaseStudies{" "}
                <img src={dropdown} alt="License Management" className="down" />
              </a>
              <div className="dropdown">
                <div className="dropdown_link" onClick={()=>handleScroll('casestudy_div')}>

                <a onClick={()=>handleScroll('service_div')}>Case Study 1</a>
                </div>
                <div className="dropdown_link" onClick={()=>handleScroll('casestudy_div')}>
                <a onClick={()=>handleScroll('service_div')}>Case Study 2 </a>
                </div>
                  <div className="dropdown_link" onClick={()=>handleScroll('casestudy_div')}>
                   <a onClick={()=>handleScroll('service_div')}>Case Study 3</a>
                  </div>
              </div>
            </li>
          </div>
        </ul>
        <div class="button-div">
            <button class="login-button" onClick={() => navigate('/login')}>Login</button>
            <button class="signup-button"onClick={() => navigate('/signup')} >Signup</button>
            
          </div>
      </nav>
    </div>
  );
};

export default Navbar;
