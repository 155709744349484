import React, { useContext } from 'react';
import './Alogin.css';
import Certificate from '../certificate.png';
import Group from '../group.png';
import Access from '../access.png';
import Card from './Card';
import { CardContext } from '../utils/Context/cardContext';

const Alogin = () => {
  const { accessCard } = useContext(CardContext);
  console.log(accessCard)

  return (
    <div className='allmain'>
      <div className='almain'>
        {accessCard?.map((item) => {
          return (
            <React.Fragment key={item}>
              {item === 'UA' && <Card isrc={Group} heading='User Management' />}
              {item === 'LM' && <Card isrc={Certificate} heading='License Management' />}
              {item === 'SOD' && <Card isrc={Access} heading='SOD' />}
              {item === 'VCONNECT' && <Card isrc={Group} heading='VConnect' />}
              {item === 'AM' && <Card isrc={Group} heading='Asset management' />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Alogin;
