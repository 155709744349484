// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Homepage.css */

.Main {
    width: 100%;
    height: 90vh; /* 100% of the viewport height */
    /* background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%); */
    /* display: flex; */
  }
  
  .Sidebox {
    width: 100px;
    height: 100%;
    background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%);
    /* Additional styles for the Sidebox */
  }
  .landingpage
  {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #3C5B6F;
    width: 100%;
    height: 100vh;
  }
.highlight{
  color:#642bff;
  font-size: 30px;
}

  .mainname
  {
    font-family: 'Rethink Sans', sans-serif;
    font-size: 100px;
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(152,148,233,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .motto
  {
    font-size: 30px;
    text-align: center;
    color: white;
    font-weight: 600;
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }`, "",{"version":3,"sources":["webpack://./src/components/Homepage.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAGjB;IACI,WAAW;IACX,YAAY,EAAE,gCAAgC;IAC9C,yEAAyE;IACzE,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,mEAAmE;IACnE,sCAAsC;EACxC;EACA;;IAEE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,yBAAyB;IACzB,WAAW;IACX,aAAa;EACf;AACF;EACE,aAAa;EACb,eAAe;AACjB;;EAEE;;IAEE,uCAAuC;IACvC,gBAAgB;IAChB,qFAAqF;IACrF,6BAA6B;IAC7B,oCAAoC;EACtC;EACA;;IAEE,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,kFAAkF;IAClF,6BAA6B;IAC7B,oCAAoC;EACtC","sourcesContent":["/* Homepage.css */\r\n@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@600&display=swap');\r\n\r\n.Main {\r\n    width: 100%;\r\n    height: 90vh; /* 100% of the viewport height */\r\n    /* background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%); */\r\n    /* display: flex; */\r\n  }\r\n  \r\n  .Sidebox {\r\n    width: 100px;\r\n    height: 100%;\r\n    background-image: linear-gradient(to top, #f43b47 0%, #453a94 100%);\r\n    /* Additional styles for the Sidebox */\r\n  }\r\n  .landingpage\r\n  {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    background-color: #3C5B6F;\r\n    width: 100%;\r\n    height: 100vh;\r\n  }\r\n.highlight{\r\n  color:#642bff;\r\n  font-size: 30px;\r\n}\r\n\r\n  .mainname\r\n  {\r\n    font-family: 'Rethink Sans', sans-serif;\r\n    font-size: 100px;\r\n    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(152,148,233,1) 100%);\r\n    -webkit-background-clip: text;\r\n    -webkit-text-fill-color: transparent;\r\n  }\r\n  .motto\r\n  {\r\n    font-size: 30px;\r\n    text-align: center;\r\n    color: white;\r\n    font-weight: 600;\r\n    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);\r\n    -webkit-background-clip: text;\r\n    -webkit-text-fill-color: transparent;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
