import { FaArrowLeft } from "react-icons/fa6"
import { Link } from "react-router-dom"
import './Sodmain.css'
import Cardd from "./Cardd"
import oracle from '../oracle.png';
import coupa from '../coupa.png';
import salesforce from '../salesforce-logo-cloudcon.png';
import jira from '../atlassian.png';
import workday from '../workday.png';
import SAP from '../SAP.jpg'

const Sodmain = () =>{
    return (
        <div className="sodmain_container">
            <div className="navbar1">
            <FaArrowLeft/> <Link to="/authpage" className="home_link">Homepage</Link>
            </div>
            <div className='limain'>
        <div>
            <Cardd name={SAP} appname="SAP"/>
        </div>
        
        <div>
            <Cardd name={salesforce} appname="salesforce"/>
        </div>
        <div>
            <Cardd name={workday} appname="Workday" />
        </div>
        <div>
            <Cardd name={jira} appname="Jira"/>
        </div>
    </div>

        </div>
    )
}
export default Sodmain