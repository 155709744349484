import React, { useContext, useEffect, useState } from 'react';
import './Configure.css';
import oracle from '../oracle.png';
import coupa from '../coupa.png';
import salesforce from '../salesforce-logo-cloudcon.png';
import jira from '../atlassian.png';
import workday from '../workday.png';
import tick from '../tick.png';
import wrong from '../wrong.png'
import axios from 'axios'
import { showToast } from '../utils/toast';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { UserContext } from '../utils/Context/userContext';

const Configure = () => {
  const {user} = useContext(UserContext)
  const [options,setOptions] = useState([])
  const [resultOptions, setResultOptions] = useState([]);


  const handleSubmit = () =>{
       console.log(resultOptions)
       
       const requestData = [];
  for (const softwareid in resultOptions) {
    requestData.push({
      softwareid: softwareid,
      nooflicence: resultOptions[softwareid]
    });
  }
       const sendData = {
        userId:user,
        selection:requestData
       }
console.log(requestData)
   axios.post('https://vmesas.com:5004/save_customer_software',sendData).then((res)=>{
    console.log(res)
   
    showToast(res.data.message)
   }).catch((err)=>{
    console.log(err)
   })

  }

  useEffect(()=>{
    axios.get('https://vmesas.com:5004/get_software').then((res)=>{
      console.log(res)
      const initialResultOptions = [];
        res.data.forEach((item) => {
          initialResultOptions[item.softwareid] = 0;
        });
        setResultOptions(initialResultOptions);
        setOptions(res.data);
    }).catch((err)=>{
      console.log(err)
    })
  },[])

  const handleInputChange = (e, softwarename) => {
    setResultOptions({
      ...resultOptions,
      [softwarename]: e.target.value,
  });
  };

  const handleCancel = ()=>{
    const initialResultOptions = [];
    options.forEach((item) => {
      initialResultOptions[item.softwareid] = 0;
    });
    setResultOptions(initialResultOptions);
  }


  return (
    <div className='configmain'>
      <div className='head_div'>
      <div className="navbar1">
        <FaArrowLeft/> <Link to="/License Management" className="home_link">Back</Link>
        </div>
      <div className='confignav'>Configure</div>
      </div>
      <div className='configcard'>
      
        {
          options.map((item)=>{
            console.log(item)
            return (
              <div className='inlo' key={item}>
                <label>No of Licenses in {item.softwarename} :</label>
              <input 
              type='number' 
              className='detail_input' 
              
              value={resultOptions[item.softwareid]}
              onChange={(e) => handleInputChange(e, item.softwareid)}
              ></input>
              </div>
            )
          })
        }
       
       
        <div className='configdn'><button className='button-68' onClick={handleSubmit}><img src={tick} className='confimg'/>Submit</button><button className='button-44' onClick={handleCancel}><img src={wrong} className='confimg'/>Cancel</button></div>
      </div>
    </div>
      );
};

export default Configure;
